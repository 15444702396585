@if (vm)
{
@if (vm.group.icon || vm.group.title)
{
<div class="asc-form-title">
   @if (vm.group.icon)
   {
   <asc-form-icon [icon]="vm.group.icon" />
   }
   @if (vm.group.title)
   {
   <h3>{{vm.group.title}}</h3>
   }
</div>
}
@if (vm.group.description)
{
<p class="asc-form-description">{{vm.group.description}}</p>
}
<div class="asc-form-properties">
   @for (property of vm.properties; track $index)
   {
   @if (displayMode === 'read-only') {
   @if (property.property.type !== 'HiddenText')
   {
   <div class="asc-form-property">
      <label class="asc-property-label">{{property.property.displayName}}</label>
      <div class="asc-property-value" [ngClass]="{'asc-no-value': !property.formControl.value}">
         @switch (property.property.type) {
         @case ('DatePicker') {
         {{(property.formControl.value | date) || '-'}}
         }
         @case ('Dropdown') {
         {{property.formControl.value?.Label || '-'}}
         }
         @default {
         {{property.formControl.value || '-'}}
         }
         }
      </div>
   </div>
   }
   }
   @else {
   @switch (property.property.type) {
   @case ('DatePicker') {
   <asc-form-field-datepicker [property]="property" />
   }
   @case ('Dropdown') {
   <asc-form-field-dropdown [property]="property" [ngClass]="{'asc-control-size-large': property.isControlSizeLarge}" />
   }
   @case ('DrowdownMultiSelection') {
   <asc-form-field-dropdown-multiple [property]="property"
      [ngClass]="{'asc-control-size-large': property.isControlSizeLarge}" />
   }
   @case ('HiddenText') {
   <!-- hidden -->
   }
   @case ('RadioButton') {
   <asc-form-field-radio-buttons [property]="property"
      [ngClass]="{'asc-control-size-large': property.isControlSizeLarge}" />
   }
   @case ('Text') {
   <asc-form-field-text [property]="property" />
   }
   @case ('TextMultiline') {
   <asc-form-field-text-multiline [property]="property" />
   }
   @default {
   <p
      style="box-sizing: border-box; border-radius: 4px; padding-top: 4px; padding-bottom: 4px; background-color: rgba(255, 0, 195, 0.05);">
      {{property.property.type}}</p>
   }
   }
   }
   }
</div>
}