import { EProviderType } from '../authentication/model/eprovider-type';
import { ApiEndpoint } from './api-endpoint';

export class ClientConfiguration {

   public production: boolean = false;

   public environment?: string;
   public title?: string;
   public version?: string;

   public apiEndpoints!: {
      core_ClientConfiguration: ApiEndpoint;
      core_ModuleSettings: ApiEndpoint;
      core_ModuleConfiguration: ApiEndpoint;
      core_User: ApiEndpoint;
      core_UserProfile: ApiEndpoint;
      core_SendTestMail: ApiEndpoint;

      iaM_Form: ApiEndpoint;
      iaM_FormData: ApiEndpoint;
      iaM_Application: ApiEndpoint;
      iaM_Applications: ApiEndpoint;
      iaM_Tasks: ApiEndpoint;

      notifications_ApproveMessage: ApiEndpoint;
      notifications_CreateMessage: ApiEndpoint;
      notifications_Message: ApiEndpoint;
      notifications_MessageTemplate: ApiEndpoint;
      notifications_MessageTemplates: ApiEndpoint;
      notifications_ModuleSettings: ApiEndpoint;
      notifications_PreviewMessage: ApiEndpoint;
      notifications_ReadMessage: ApiEndpoint;
      notifications_ReadMessages: ApiEndpoint;
      notifications_RecipientCollectionEntries: ApiEndpoint;
      notifications_RecipientCollections: ApiEndpoint;
      notifications_RemoveMessage: ApiEndpoint;

      passwordManagement_PasswordValidationRules: ApiEndpoint;
      passwordManagement_Start: ApiEndpoint;

      suSPasswordReset_PasswordValidationRules: ApiEndpoint;
      suSPasswordReset_Start: ApiEndpoint;
      suSPasswordReset_Students: ApiEndpoint;

      software_WindowsClientSetup: ApiEndpoint;

      userProfiles_UserProfile: ApiEndpoint;
   };

   authentication!: {
      tenant: string;
      clientId: string;
      provider: EProviderType;
      redirectUri: string;
      postLogoutRedirectUri: string;
      enableLogging: boolean;
      scopesAsc: string[];
      scopesMicrosoftGraph: string[];
      endpoints: string[];
   };

   telemetry!: {
      applicationInsightsInstrumentationKey: string;
   };


   /**
    * Gets the value indicating whether the specified url is protected by AAD
    * and can only be accessed with an access token.
    * @param url 
    * @returns 
    */
   public apiEndpointRequiresAuthentication(url: string): boolean {
      const apiEndpointUrl: string = url.split('?')[0];

      for (const property in this.apiEndpoints) {
         const apiEndpoint: ApiEndpoint = (<any>this.apiEndpoints)[property] as ApiEndpoint;
         if (apiEndpoint && apiEndpoint.url === apiEndpointUrl && apiEndpoint.secured) { return true; }
      }

      return false;
   }
}
