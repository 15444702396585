// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { ApiEndpoint } from "src/modules/core/model/api-endpoint";

export const environment = {
   production: false,

   environment: 'ari-test',
   title: 'ARI Service Center',
   version: '2.0.2439',

   clientEndpoints: {
      core_ClientConfiguration: new ApiEndpoint(window.location.origin + '/Core_HttpClientConfiguration'),
      clientSetupAndroid: new ApiEndpoint('https://play.google.com/store/apps/details?id=ch.arinformatikag.servicecenter'),
      clientSetupIos: new ApiEndpoint('https://apps.apple.com/us/app/asc-ari-service-center/id1659998676')
   }
};
