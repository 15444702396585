export enum EProviderType {
   /**
    * Unknown account type
    */
   Anonymous,
   /**
    * Microsoft Authentication Library (MSAL)
    */
   MSAL
}
