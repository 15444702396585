import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormFieldTextComponent } from './components/form-field-text/form-field-text.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormSectionNavigationComponent } from './components/form-section-navigation/form-section-navigation.component';
import { FormIconComponent } from './components/form-icon/form-icon.component';
import { FormSectionComponent } from './components/form-section/form-section.component';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { MatInputModule } from '@angular/material/input';
import { FormFieldTextMultilineComponent } from './components/form-field-text-multiline/form-field-text-multiline.component';
import { FormFieldDropdownComponent } from './components/form-field-dropdown/form-field-dropdown.component';
import { MatSelectModule } from '@angular/material/select';
import { FormFieldDatepickerComponent } from './components/form-field-datepicker/form-field-datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormService } from './services/form.service';
import { FormFieldRadioButtonsComponent } from './components/form-field-radio-buttons/form-field-radio-buttons.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormFieldDropdownMultipleComponent } from './components/form-field-dropdown-multiple/form-field-dropdown-multiple.component';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



@NgModule({
   declarations: [
      FormFieldTextComponent,
      FormIconComponent,
      FormSectionComponent,
      FormSectionNavigationComponent,
      FormGroupComponent,
      FormFieldDatepickerComponent,
      FormFieldDropdownComponent,
      FormFieldDropdownMultipleComponent,
      FormFieldRadioButtonsComponent,
      FormFieldTextComponent,
      FormFieldTextMultilineComponent
   ],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,

      MatAutocompleteModule,
      MatDatepickerModule,
      MatIconModule,
      MatFormFieldModule,
      MatInputModule,
      MatRadioModule,
      MatSelectModule
   ],
   exports: [
      FormSectionComponent,
      FormSectionNavigationComponent
   ],
   providers: [
      provideMomentDateAdapter(),
      FormService
   ]
})
export class FormModule {
}
