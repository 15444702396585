import { Injectable, Injector, createNgModule } from '@angular/core';
import { Routes, Route } from '@angular/router';

import { IAscModule, IAscModuleClass } from 'src/modules/i-asc-module';
import { moduleCatalog } from 'src/modules/module-catalog';
import { IAscModuleDefinition } from '../../i-asc-module-definition';
import { FunctionClient } from '../azure-functions';
import { ClientModuleConfiguration } from '../model/client-module-configuraton';
import { INavigationItem } from '../model/i-navigation-item';
import { ModuleSettings } from '../model/module-settings';
import { ClientConfiguration } from '../model/client-configuraton';
import { TelemetryService } from './telemetry.service';
import { EAppInsightsSeverityLevel } from '../model/application-insights';


@Injectable()
export class ModuleManagerService {

   public isBusy = false;
   public moduleDefinitions: IAscModuleDefinition[] = [];
   public moduleConfigurations: ClientModuleConfiguration[] = [];
   public featureNavigationItems: INavigationItem[] = [];
   public settingsNavigationItems: INavigationItem[] = [];
   private controllerModuleConfiguration!: FunctionClient;
   private controllerModuleSettings!: FunctionClient;
   private modules: IAscModule[] = [];

   constructor(
      private injector: Injector,
      private telemetryService: TelemetryService,
   ) { }

   public initialize(clientConfiguration: ClientConfiguration) {
      this.controllerModuleConfiguration = new FunctionClient(this.telemetryService, clientConfiguration.apiEndpoints.core_ModuleConfiguration);
      this.controllerModuleSettings = new FunctionClient(this.telemetryService, clientConfiguration.apiEndpoints.core_ModuleSettings);
   }

   /**
    * Loads the module configuration for the modules available for the current user.
    */
   public async loadModuleConfigurationAsync(userPrincipalName: string): Promise<ClientModuleConfiguration[]> {
      try {
         this.isBusy = true;

         let url = this.controllerModuleConfiguration.url;
         if (userPrincipalName) { url += `?userPrincipalName=${userPrincipalName}`; }

         const result = await this.controllerModuleConfiguration.callFunctionGetAsync<ClientModuleConfiguration[]>(url);
         this.moduleConfigurations = ((result as any[]) || []).map(item => ClientModuleConfiguration.parse(item));

         console.info('[Module-Manager]', 'Module configuration loaded.');
         return this.moduleConfigurations;
      }
      finally {
         this.isBusy = false;
      }
   }

   /**
    * Loads all modules specified by name.
    */
   public async loadModulesAsync(moduleConfigurations: ClientModuleConfiguration[]): Promise<void> {
      try {
         this.isBusy = true;

         const featureNavigationItems: INavigationItem[] = [];
         const settingsNavigationItems: INavigationItem[] = [];

      // Load modules and get module definitions
      const moduleDefinitionsTasks = (moduleConfigurations || []).map(async moduleConfiguration => {
         try {
            // Check if module is already loaded
            if ((<any>this.modules)[moduleConfiguration.id]) { return; }

               // Get module reference from catalog
               const moduleDefinition: IAscModuleDefinition | undefined = moduleCatalog.find(item => item.moduleId === moduleConfiguration.id);
               if (!moduleDefinition) {
                  throw new Error(`Module '${moduleConfiguration.id}' is undefined.`);
               }

               // Load module
               const moduleClass: IAscModuleClass = await (moduleDefinition.moduleRoute.loadChildren as () => Promise<IAscModuleClass>)();
               const moduleRef = createNgModule(moduleClass, this.injector);
               const module: IAscModule = moduleRef.instance;
               module.setModuleConfiguration(moduleConfiguration);
               (<any>this.modules)[moduleConfiguration.id] = module;

               // Get feature navigation items (dashborad & menu)
               (module.getFeatureNavigationItems(moduleConfiguration) || []).forEach(item => { featureNavigationItems.push(item); });

               // Get settings navigation items (menu)
               (module.getSettingsNavigationItems(moduleConfiguration) || []).forEach(item => { settingsNavigationItems.push(item); });

            console.info('[Module-Manager]', `Module ${moduleConfiguration.id} loaded.`);
         }
         catch (error) {
            const message = `Error loading module '${moduleConfiguration.id}'.`;
            console.error('[Module-Manager]', message, error);
            this.telemetryService.logException(new Error(error as string), undefined, EAppInsightsSeverityLevel.Error, { appName: ModuleManagerService.name });
            //throw new Error(message);
         }
      });

         // Execure tasks
         await Promise.all(moduleDefinitionsTasks);

         this.telemetryService.logTrace(
            'Modules loaded.',
            EAppInsightsSeverityLevel.Information,
            {
               modules: JSON.stringify(Object.keys(this.modules))
            });

         // Sort navigation items
         this.featureNavigationItems = featureNavigationItems.sort((a, b) => (a.sortIndex - b.sortIndex));
         this.settingsNavigationItems = settingsNavigationItems.sort((a, b) => (a.sortIndex - b.sortIndex));
      }
      finally {
         this.isBusy = false;
      }
   }

   public getModuleRoutes(config: Routes, moduleIDs: string[]): Routes {
      const routes: Routes = [];
      moduleIDs.forEach(moduleID => {
         const moduleDefinition: IAscModuleDefinition | undefined = moduleCatalog.find(item => item.moduleId === moduleID);
         if (moduleDefinition) {
            this.addRoute(config, moduleDefinition.moduleRoute);
            if (moduleDefinition.settingsRoute) { this.addRoute(config, moduleDefinition.settingsRoute); }
         }
      });
      return config;
   }

   private addRoute(routes: Routes, route: Route /* { path: string, loadChildren: () => Promise<IAscModuleClass> }*/) {
      if (!route.path) return;

      // Merge child routes (for /settings)
      if (route.path.indexOf('/') > -1) {
         const pathSegment = route.path!.substring(0, route.path!.indexOf('/'));
         const parentRoute = routes.find(r => (r.path === pathSegment));
         if (parentRoute) {
            if (!parentRoute.children) { parentRoute.children = [] as Routes; }

            const childRoute = {
               path: route.path!.substring(route.path!.indexOf('/') + 1),
               loadChildren: route.loadChildren
            };
            this.addRoute(parentRoute.children, childRoute);
            return;
         }
      }

      // Add route
      routes.push(route);
   }

   // --------------------------------------------------------- Module settings

   public getModuleSettings(moduleId: string): ModuleSettings | undefined {
      const moduleConfiguration = this.moduleConfigurations.find(item => item.id === moduleId);
      return (moduleConfiguration) ? moduleConfiguration.settings : undefined;
   }

   public async saveModuleSettingsAsync(settingsTypeDiscriminator?: number): Promise<void> {
      try {
         this.isBusy = true;

         const moduleSettingsList: ModuleSettings[] = [];
         (this.moduleConfigurations || []).forEach(moduleConfiguration => {
            if (moduleConfiguration.settings && (!settingsTypeDiscriminator || settingsTypeDiscriminator === moduleConfiguration.settings.typeDiscriminator)) {
               moduleSettingsList.push(moduleConfiguration.settings);
            }
         });

         const url = `${this.controllerModuleSettings.url}`;
         await this.controllerModuleSettings.callFunctionPutAsync<any>(url, moduleSettingsList);
      }
      catch (error) {
         console.error('[Module-Manager]', 'Error while saving module settings.', error);
         throw error;
      }
      finally {
         this.isBusy = false;
      }
   }
}
