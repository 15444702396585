import { Component, Input } from '@angular/core';
import { PropertyGroupViewModel } from '../../view-model/property-group-view-model';


@Component({
   selector: 'asc-form-group',
   templateUrl: './form-group.component.html',
   styleUrls: ['./form-group.component.scss'],
})
export class FormGroupComponent {

   @Input('group')
   public vm?: PropertyGroupViewModel;

   @Input('displayMode')
   public displayMode?: 'edit' | 'read-only' | undefined;

   @Input('showEmptyProperties')
   public showEmptyProperties = true;
}
